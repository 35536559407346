// social-media-approver.component.js
import templateUrl from './social-media-approver.template.html';

// Define the component
class SocialMediaCuratorController {
	constructor($log, $interval, $timeout, ApiService) {
		'ngInject';
		this._$log = $log;
		this._$interval = $interval;
		this._$timeout = $timeout;
		this._ApiService = ApiService;
		this._posts = [];
		this._selectedApprovalStatus = 0;
		this._maxTextLength = 200;
	}

	$onInit() {
		this.fetchPosts();
	}

	fetchPosts() {
		this._$log.info('Fetching posts for curation');
		this._ApiService.call('socialmediapost/getPostsForCuration', { approvalStatus: this._selectedApprovalStatus })
			.then(posts => {
				this._$log.info(`Fetched ${posts.length} posts for curation`);
				this._posts = posts;
				this._posts.forEach(post => {
					post.isExpanded = false;
					post.truncatedText = this.truncateText(post.caption, this._maxTextLength);
				});
			})
			.catch(() => {
				this._$log.error('Error fetching posts for curation');
			});
	}

	updatePostApproval(postId, approvalStatus) {
		this._ApiService.call('socialmediapost/updateSocialMediaPost', { id: postId, approvalStatus })
			.then(p => {
				const index = this._posts.findIndex(item => item.id === postId);
				const postlist = this._posts;
				this._$log.info(`Index`, { index, p, postlist });
				if (index !== -1) {
					// remove the post from the list
					this._posts.splice(index, 1);
				}
			})
			.catch(() => {
				this._$log.error('Error updating post approval state');
			});
	}

	shouldTruncate(text) {
		return text.length > this._maxTextLength + 4;
	}

	truncateText(text, maxLength) {
		return this.shouldTruncate(text) ? text.substring(0, maxLength) + '...' : text;
	}

	toggleText(post) {
		post.isExpanded = !post.isExpanded;
	}
}

export default {
	controller: SocialMediaCuratorController,
	templateUrl
};
